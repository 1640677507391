import axios from "axios";
import { getCookie } from "../../../../../utils/sessionCookie";

const useAxios = () => {

  const checkZipCodeExistance = async (zipcode) => {
    // console.log("## EXECUTING checkZipCodeExistance ##")
    const url = process.env.REACT_APP_API_URL + "zones/check-existence-postal-code";
    return await axios
      .post(url, { postal_code: zipcode })
      .then(response => {
        const { validated, id_postal_code } = response.data;
        return { validated, id_postal_code, response: true };
      })
      .catch(error => {
        return { response: false }
      });
  }

  const getZipCodeInfo = async (zipcode) => {
    // console.log("## EXECUTING getZipCodeInfo ##")
    const url = process.env.REACT_APP_API_URL + "zones/get-info-postal-code";
    return await axios
      .post(url, { postal_code: zipcode })
      .then(response => {
        const { info_postal_code } = response.data;
        return { info_postal_code, response: true };
      })
      .catch(error => {
        return { response: false }
      });
  }


  const addWareHouse = async (form) => {
    // console.log("## EXECUTING addWareHouse ##");
    const {
      user_name,
      paternal_lastname,
      maternal_lastname,
      telephone_number,
      email,
      password,
      id_postal_code,
      alias,
      state,
      city,
      colony,
      street_name,
      ext_number,
      int_number,
      references
    } = form;

    const data = {
      user_info: {
        user_name: user_name,
        paternal_lastname: paternal_lastname,
        maternal_lastname: maternal_lastname,
        telephone_number: telephone_number,
        email: email,
        password: password,
      },
      warehouse_location: {
        id_postal_code: id_postal_code,
        alias: alias,
        state: state,
        city: city,
        colony: colony,
        street_name: street_name,
        ext_number: ext_number,
        int_number: int_number,
        references: references,
      }
    }
    const url = process.env.REACT_APP_API_URL + "admin/add-warehouse";
    const token = getCookie("accessToken");
    const headers = {
      "auth_token": token,
      "Content-Type": "application/json;charset=UTF-8"
    }

    return await axios
      .post(url, data, { headers })
      .then(response => {
        return { response: true };
      })
      .catch(error => {
        return { response: false };
      });
  }

  return {
    checkZipCodeExistance,
    getZipCodeInfo,
    addWareHouse
  }
}


export default useAxios;

